<template>
  <div class="warranty-container" v-if="warrantyPageData" v-html="warrantyPageData"></div>
</template>

<script>
import { useGetters } from '@/lib/helpers'
export default {
  setup () {
    const { warrantyPageData } = useGetters(['warrantyPageData'])
    return { warrantyPageData }
  }
}
</script>

<style lang="scss" scoped>
.warranty-container {
  text-align: left;
  max-width: 900px;
  margin: 0 auto;
}
</style>
